<template>
  <div
    class="d-flex"
    :class="$store.getters['layout/getInnerWidth'] >= 991 ? 'justify-content-center' : 'justify-content-left'"
    v-if="invoiceUrl"
  >

   <span
     class="text-blue-light"
     @click="showBill"
   >
     <i class="pointer fa fa-file"></i>
   </span>
  </div>
</template>
<script>
export default {
  props: {
    invoiceUrl: {
      type: String,
      default: null
    }
  },
  methods: {
    showBill() {
      if (null !== this.invoiceUrl) {
        this.openInNewTab(this.invoiceUrl);
      }
    },
    openInNewTab(href) {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href: href,
      }).click();
    }
  }
}
</script>
<style lang="scss" scoped>
.fa-file {
  font-size: 20px;
}

.not-allowed {
  cursor: not-allowed;
}
</style>
